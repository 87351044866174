//import Footer from './Footer'
import floors from "../data/floors";
import { useEffect, useState } from 'react';
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import domain from "./domain";
import Slider from "react-slick";

const PopupFloor = ({data, close, showPopupFloor, showLabel, hideLabel, showPopup}) => {
		const imgParams = '?v=2'

    // const frmt = new Intl.NumberFormat()

    // const [showPrice, setShowPrice] = useState(false)

    // useEffect(() => {
    //     const popup = document.querySelector('.popup')
    //     popup.scrollTop = 0
    // }, [data]);

    // const toPlan = () => {
    //     const popup = document.querySelector('.popup')
    //     const element = document.querySelector('.popup__plan-title')
    //     popup.scrollTo({top: element.offsetTop, behavior: 'smooth'})
    // }

    return(
        <div className="popup popup-floor">
            <div className="popup__head">
                <div className="popup__menu-burger">
									<div className="menu-burger"><span /></div>
								</div>
                <div>
                    <img src="/img/logo_b.svg" alt="" />
                </div>
                <div className="popup__back" onClick={close}>
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.469669 5.96967C0.176776 6.26256 0.176776 6.73744 0.469669 7.03033L5.24264 11.8033C5.53553 12.0962 6.01041 12.0962 6.3033 11.8033C6.59619 11.5104 6.59619 11.0355 6.3033 10.7426L2.06066 6.5L6.3033 2.25736C6.59619 1.96447 6.59619 1.48959 6.3033 1.1967C6.01041 0.903805 5.53553 0.903805 5.24264 1.1967L0.469669 5.96967ZM16 5.75L0.999999 5.75L0.999999 7.25L16 7.25L16 5.75Z" fill="#44453F"/>
                    </svg>
                    <span>Назад к выбору</span>
                </div>
            </div>

            <div className="popup__in">

								<div className="popup__in-top popup-floor__in-top">
										<div className={'p_c'}>
												<div className="popup-floor__plan">
														<div className="popup-floor__plan-text">Брюсов переулок</div>

														<div className="popup-floor__plan-floor">
																<svg 
																		width={floors[data.popupFloor]?.size[0]} 
																		height={floors[data.popupFloor]?.size[1]} 
																		viewBox={`0 0 ${floors[data.popupFloor].size[0]} ${floors[data.popupFloor].size[1]}`}
																>
																		{floors[data.popupFloor]?.flats?.map((item, index) => data && data.popupFloor && 
																				<path 
																						d={item.path} 
																						fill={'#fff'} 
																						key={`item${index}`} 
																						onClick={() => showPopup({floor: data.popupFloor, number: item.number})} 
																						onMouseEnter={e => showLabel(e, data.popupFloor, item.number)}
																						onMouseLeave={() => hideLabel()}
																				/>
																		)}
																</svg>
																<img 
																		src={data.floorParams?.[data.popupFloor]['floor-plan'] + imgParams} 
																		alt="" 
																/>
																{/* <img 
																		src={floors[data.popupFloor]?.img} 
																		alt="" 
																/> */}
														</div>

														<div className="popup-floor__plan-text">Двор</div>
												</div>
										</div>
										<div className={'p_r'}>
												<div>
														<div className="popup-floor__plan-nav">

																<div 
																		className={'popup-floor__plan-nav-item'+(data.nextFloor ? '':' disabled')}
																		onClick={() => showPopupFloor(data.nextFloor)}
																>
																		<span className="floor-number">{data.nextFloor || ''}</span>
																</div>

																<div className="popup-floor__plan-nav-item active">
																		<span className="floor-number">{data.popupFloor}</span>
																		<span className="floor-text">Этаж</span>
																</div>

																<div 
																		className={'popup-floor__plan-nav-item'+(data.prevFloor ? '':' disabled')}
																		onClick={() => showPopupFloor(data.prevFloor)}
																>
																		<span className="floor-number">{data.prevFloor || ''}</span>
																</div>

														</div>
												</div>
												<div>
														{data?.floorGenplan && <div>
																<img src={data.floorGenplan + imgParams} alt="" className="popup__mini-genplan" />
																<div>
																		<img className={'view_rose'} src={'/img/view_rose.svg'} alt="" />
																</div>
														</div>}
												</div>
										</div>
								</div>

								<iframe className="popup__pano" src="https://tour.virtualland.ru/brusov/window-views/" title="pano" />
            </div>

            {/*<Footer />*/}
        </div>
    )
}

export default PopupFloor
