import Slider from "react-slick";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import domain from "./domain";

const PopupFlat = ({data, close}) => {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    console.log(data);

    return(
        <div className="popup-flat">
            <div onClick={close} className="popup-flat__close">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="50" height="50" rx="25" fill="#44453F"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0108 24.9996L16.0059 33.0045L16.9958 33.9945L25.0007 25.9895L33.0056 33.9945L33.9956 33.0045L25.9907 24.9996L33.9954 16.9948L33.0055 16.0049L25.0007 24.0096L16.996 16.0049L16.006 16.9948L24.0108 24.9996Z" fill="white"/>
                </svg>

            </div>

{/*            <Slider {...settings} className="popup-flat__slider">
                {data?.images?.map((item, index) => (
                    <div>
                        <TransformWrapper>

                            {({ zoomIn, zoomOut, ...rest }) => (
                                <>
                                    <div className="zoom-tools">
                                        <div onClick={() => zoomIn()}>
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="50" height="50" rx="25" fill="#44453F"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3 25.7V35H25.7V25.7H35V24.3H25.7V15H24.3V24.3H15V25.7H24.3Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div onClick={() => zoomOut()}>
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="50" height="50" rx="25" fill="#44453F"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35 25.6998L15 25.6998L15 24.2998L35 24.2998V25.6998Z" fill="white"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <TransformComponent>
                                        <img src={domain + item} key={`item${index}`} alt="" />
                                    </TransformComponent>
                                </>
                            )}
                        </TransformWrapper>
                    </div>
                ))}
            </Slider>*/}

            <Slider {...settings} className="popup-flat__slider">
                <div>
                    <TransformWrapper>

                        {({ zoomIn, zoomOut, ...rest }) => (
                            <>
                                <div className="zoom-tools">
                                    <div onClick={() => zoomIn()}>
                                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="50" height="50" rx="25" fill="#44453F"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3 25.7V35H25.7V25.7H35V24.3H25.7V15H24.3V24.3H15V25.7H24.3Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div onClick={() => zoomOut()}>
                                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="50" height="50" rx="25" fill="#44453F"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M35 25.6998L15 25.6998L15 24.2998L35 24.2998V25.6998Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                                <TransformComponent>
                                    <img src={data?.images} key={`item`} alt="" />
                                </TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                </div>
            </Slider>

            <div className="popup-flat__imgs">
                {/*<div>
          <img src={data?.imagePlan} alt="" />
        </div>*/}
                <div>
                    {/*<img src="/img/view_rose.svg" alt="" />*/}
                </div>
            </div>
        </div>
    )
}

export default PopupFlat
