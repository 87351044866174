import domain from "./domain";

const PopupImage = ({data, close}) => {
  return(
    <div className="popup-flat">
      <div onClick={close} className="popup-flat__close">
        <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="1.06452" y1="-1.06452" x2="42.5806" y2="-1.06452" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 31.9355 34.0644)" stroke="#B5B5B1" strokeWidth="2.12903" strokeLinecap="square"/>
          <line x1="1.06452" y1="-1.06452" x2="42.5806" y2="-1.06452" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0 31.9356)" stroke="#B5B5B1" strokeWidth="2.12903" strokeLinecap="square"/>
        </svg>
      </div>

      <div className="popup-img-in" style={{ backgroundImage: `url('${domain + data}')` }}></div>
    </div>
  )
}

export default PopupImage