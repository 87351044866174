import { useEffect, useState, Fragment  } from "react";
import Select from '../ui/Select'
import PopupFloor from './PopupFloor'
import Popup from './Popup'
import PopupFlat from './PopupFlat'
import PopupImage from './PopupImage'
import build from "../data/build";
//import flats from '../data/flats'
import axios from 'axios'
import domain from "./domain";


const Layout = () => {
    const [step, setStep] = useState(0)
    const [view, setView] = useState([])
    const [tip, settip] = useState([])
    const [features, setFeatures] = useState([])

    const [flats, setFlats] = useState([])
    const [isShowFilter, setIsShowFilter] = useState(false)
    const [isBack, setIsBack] = useState(false)
    const [coef, setCoef] = useState(window.innerWidth/1920)

    const [popupFloor, setPopupFloor] = useState(null)
    const [prevFloor, setPrevFloor] = useState(null)
    const [nextFloor, setNextFloor] = useState(null)
    const [floorsArray, setFloorsArray] = useState([])
    const [floorParams, setFloorParams] = useState(null)
    const [floorGenplan, setFloorGenplan] = useState(null)
    const [label, setLabel] = useState(null)
    const [labelPos, setLabelPos] = useState(null)

    const [popup, setPopup] = useState(null)
    const [popupFlat, setPopupFlat] = useState(null)
    const [popupImage, setPopupImage] = useState(null)

    const resize = () => {
        setCoef(window.innerWidth/1920)
    }

    useEffect(() => {

        axios.get(`${domain}/api/?page=flats`)
            .then(function (response) {
                setFlats(response.data);
                setFloorsArray(getFloorsArray(response.data));
                setFloorParams(getFloorParams(response.data));
            })
            .catch(function (error) {
                console.log(error);
            })

        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    },[])

    useEffect(() => {
        if(flats.length){
            let params = new URLSearchParams(window.location.search)
            let floor = params.get("floor")
            let apartment = params.get("apartment")

            if(apartment && apartment.length){
                const element = flats.filter(inner => inner.number === apartment)
                if(element.length){
                    setPopup(element[0])
                    setStep(1)
                }
            }else if(floor && floor.length){
                showPopupFloor(floor)
            }
        }
    },[flats])

    const showPopupFloor = floor => {
        setPrevFloor(getPrevFloor(floor))
        setNextFloor(getNextFloor(floor))
        setPopupFloor(floor)
        setStep(1)
        updateUrl(`?floor=${floor}`)
    }

    function getFloorsArray(array){
        let newArray = [];
        array.forEach(flat => {
            if (!newArray.includes(flat['floor'])) newArray.push(flat['floor'])
        })
        return newArray
    }
    function getFloorParams(array){
        let plans = {};
        array.forEach(flat => {
            if (!plans.hasOwnProperty(flat['floor'])) {
                plans[flat['floor']] = {
                    'floor-plan': flat['floor-plan'] || null,
                    'pano': flat['pono'] || null
                }
            }
        })
        return plans
    }
    function getPrevFloor(floor){
        let prev = null
        floorsArray.forEach((el, i) => {
            if (el === floor && floorsArray[i - 1]) prev = floorsArray[i - 1]
        })
        return prev;
    }
    function getNextFloor(floor){
        let next = null;
        floorsArray.forEach((el, i) => {
            if (el === floor && floorsArray[i + 1]) next = floorsArray[i + 1]
        })
        return next;
    }

    const showPopup = item => {
        const element = flats.filter(inner => inner.floor === item.floor && inner.number === item.number)
        if(element.length){
            updateUrl(`?floor=${element[0].floor}&apartment=${element[0].number}`)
            setPopup(element[0])
        }
    }

    const showLabel = (e, floor, number) => {
        if(floor && number){
            const element = flats.filter(inner => inner.floor === floor && inner.number === number)
            let t = e.clientY;
            let l = e.clientX;
            if (t<0) t = 0;
            if(element.length){
                setLabel(element[0])
				setFloorGenplan(element[0].genplan)
                setLabelPos({x: l, y: t})
                setTimeout(function (){
                    let label = document.querySelector('.render-label');
                    if(label && t-label.clientHeight<0){
                        t = label.clientHeight+25;
                        setLabelPos({x: l, y: t})
                    }
                },0)
            }
        }
    }

    const hideLabel = () => {
        setLabel(null)
        setFloorGenplan(null)
        setLabelPos(null)
    }

    const hoverFloor = (floor, isHover) => {
        let elems = document.querySelectorAll(`path[data-floor="${floor}"], path[data-floor-label="${floor}"], path[data-floor-number="${floor}"]`);

        elems.forEach(el => {
            if(isHover){
                el.classList.add('is-hover');
            }else{
                el.classList.remove('is-hover');
            }
        })
    }

    const createtip = () => {
        const tmp = new Set()

        flats.forEach(item => {
            tmp.add(item.tip)
        });

        const d = Array.from(tmp).filter(item => !!item).sort();
        let d_f = d;
        const d_left = d_f.splice(0, Math.ceil(d.length / 2));
        const d_right = d.splice(Math.ceil(d.length / 2)-1, d.length);
        return {names: [d_left, d_right], values: [d_left, d_right]}
    }

    const createView = () => {
        const names = []
        const values = []

/*        flats.forEach(item => {
            if(Array.isArray(item.filterView)){
                item.filterView.forEach(inner => {
                    if(!names.includes(inner.name)){
                        names.push(inner.name)
                        values.push(inner.value)
                    }
                });
            }
        });*/
        flats.forEach(item => {
            if(item.view){
                if(!names.includes(item.view)){
                    names.push(item.view);
                    values.push(item.view);
                }
            }else if(item.window){
                if(!names.includes(item.window)){
                    names.push(item.window);
                    values.push(item.window);
                }
            }
        });

        return {names, values}
    }

    const createFeature = () => {
        const names = []
        const values = []

        flats.forEach(item => {
            if(Array.isArray(item.features)){
                item.features.forEach(inner => {
                    if(!names.includes(inner.name)){
                        names.push(inner.name)
                        values.push(inner.value)
                    }
                });
            }
        });

        return {names, values}
    }

    const containsArrayInArray = (a, values) => {
        if(Array.isArray(values)){
            const b = values.map(item => item.value)
            if(Array.isArray(a) && Array.isArray(b)){
                for(let i = 0; i < a.length; i++){
                    if(b.includes(a[i])){
                        return true
                    }
                }
            }
        }

        return false
    }

    const getCurrentFlat = e => {
        const current = flats.filter(item => item.floor === e.floor && e.flats.includes(item.number))
        if(Array.isArray(current) && current.length){
            return current[0]
        }
        return {}
    }

    function updateUrl(params = '') {
        // window.history.pushState("", document.title, window.location.pathname + window.location.search);
        window.history.pushState("", document.title, window.location.origin + params);
    }

    //const filteredFront = build.front.filter(item => ( !tip.length || tip.includes(getCurrentFlat(item).tip) ) && ( !view.length || containsArrayInArray(view, getCurrentFlat(item).view) ) && ( !features.length || containsArrayInArray(features, getCurrentFlat(item).features) ) )
    //const filteredBack = build.back.filter(item => ( !tip.length || tip.includes(getCurrentFlat(item).tip) ) && ( !view.length || containsArrayInArray(view, getCurrentFlat(item).view) ) && ( !features.length || containsArrayInArray(features, getCurrentFlat(item).features) ) )

    const filteredFront = build.front.filter(item => Object.keys(getCurrentFlat(item)).length && (!tip.length || tip.includes(getCurrentFlat(item).tip) ) && ( !view.length || view.includes(getCurrentFlat(item).view) || view.includes(getCurrentFlat(item).window) ) && ( !features.length || containsArrayInArray(features, getCurrentFlat(item).features) ) )
    const filteredBack = build.back.filter(item =>  Object.keys(getCurrentFlat(item)).length && ( !tip.length || tip.includes(getCurrentFlat(item).tip) ) && ( !view.length || view.includes(getCurrentFlat(item).view) || view.includes(getCurrentFlat(item).window) ) && ( !features.length || containsArrayInArray(features, getCurrentFlat(item).features) ) )

    return (
        <>
            {popupFloor &&
                <PopupFloor
                    data={{popupFloor, prevFloor, nextFloor, floorGenplan, floorParams}}
                    close={() => {
                        setPopupFloor(null);
                        updateUrl()
                    }}
                    showPopupFloor={showPopupFloor}
                    showLabel={showLabel}
                    hideLabel={hideLabel}
                    showPopup={showPopup}
                />
            }
            {popup &&
                <Popup
                    data={popup}
                    close={(floor) => {
                        setPopup(false);
                        showPopupFloor(floor)
                    }}
                    setPopupFlat={() => setPopupFlat({images: popup.plan, imagePlan: popup.imgPlan})}
                    showPopup={showPopup}
                    setPopupImage={setPopupImage}
                />
            }
            {popupFlat &&
                <PopupFlat
                    data={popupFlat}
                    close={() => setPopupFlat(null)}
                />
            }
            {popupImage &&
                <PopupImage
                    data={popupImage}
                    close={() => setPopupImage(null)}
                />
            }
            <div className="wrap">

                <div className={'welcome '+(step === 0 ? 'active':'')}>
                    <div className={'bg_main'} style={{ backgroundImage: `url(/img/main.webp)` }}/>
                    <img alt={'logo'} src={'/img/logo.svg'} className={'logo'}/>

                    <img alt={'logo'} src={'/img/logo_title.svg'} className={'logo_title'}/>
                    <h1>
                        Адрес, который ждали
                        <p>Брюсов переулок, 2</p>
                    </h1>
                    <div className={'but'} onClick={() => setStep(1)}><span>Выбрать квартиру</span></div>
                </div>

                <div className={'build '+(step === 1 ? 'active':'')}>
                    <div className="build__menu-burger">
                        <div
                            className={'menu-burger'+(isShowFilter ? ' open':'')}
                            onClick={() => setIsShowFilter(!isShowFilter)}
                        ><span /></div>
                    </div>
                    <img src="/img/logo_m.svg" alt="" className="build__logo" />

                    <div className="but rotate" onClick={() => setIsBack(!isBack)}>
                        {!isBack ? <span>вид из двора</span> : <span>вид с переулка</span>}
                        <svg width="38" height="21" viewBox="0 0 38 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.4546 15.012C26.0208 15.0963 25.7376 15.5162 25.8218 15.9499C25.9061 16.3837 26.326 16.6669 26.7597 16.5827L26.4546 15.012ZM36.0498 6.59543L36.6707 6.09101V6.09101L36.0498 6.59543ZM27.85 2.46913L27.6668 3.24787L27.85 2.46913ZM15.1348 1.67497L15.2078 2.47164L15.1348 1.67497ZM4.39171 4.61815L4.79246 5.31054L4.39171 4.61815ZM1.10196 9.79701L1.87687 9.59826L1.10196 9.79701ZM6.94401 14.5692L7.22545 13.8204H7.22545L6.94401 14.5692ZM18.9372 16.5L11.3738 11.1963L10.5624 20.3982L18.9372 16.5ZM26.7597 16.5827C30.8362 15.7906 34.2022 14.3797 36.1089 12.5215L34.9923 11.3756C33.4143 12.9134 30.4114 14.2432 26.4546 15.012L26.7597 16.5827ZM36.1089 12.5215C37.0744 11.5807 37.676 10.5131 37.783 9.36549C37.8909 8.20796 37.4842 7.0924 36.6707 6.09101L35.4288 7.09985C36.0363 7.8476 36.2512 8.55965 36.1899 9.21694C36.1277 9.88409 35.7692 10.6186 34.9923 11.3756L36.1089 12.5215ZM36.6707 6.09101C35.0809 4.13401 31.948 2.6114 28.0332 1.69039L27.6668 3.24787C31.4703 4.1427 34.1768 5.55857 35.4288 7.09985L36.6707 6.09101ZM28.0332 1.69039C24.0824 0.760933 19.4679 0.474668 15.0618 0.87831L15.2078 2.47164C19.4587 2.08221 23.8991 2.36149 27.6668 3.24787L28.0332 1.69039ZM15.0618 0.87831C10.666 1.28101 6.71554 2.34881 3.99096 3.92576L4.79246 5.31054C7.24654 3.89014 10.9466 2.86201 15.2078 2.47164L15.0618 0.87831ZM3.99096 3.92576C1.30706 5.47917 -0.282521 7.61916 0.32704 9.99576L1.87687 9.59826C1.54454 8.30252 2.2977 6.75447 4.79246 5.31054L3.99096 3.92576ZM0.32704 9.99576C0.618451 11.1319 1.38642 12.151 2.45938 13.0274C3.53557 13.9066 4.96163 14.6788 6.66256 15.3181L7.22545 13.8204C5.63544 13.2228 4.37595 12.5271 3.47159 11.7883C2.56399 11.0469 2.05641 10.2982 1.87687 9.59826L0.32704 9.99576ZM6.66256 15.3181C8.15625 15.8795 9.83319 16.3275 11.6209 16.6544L11.9088 15.0806C10.1984 14.7677 8.61551 14.3428 7.22545 13.8204L6.66256 15.3181Z" fill="white"/>
                        </svg>
                    </div>
                    {isShowFilter && <div className="filter_out">
                        <div className="filter">
                            <div className="filter__in">
                                <Select
                                    label="вид из окон"
                                    data={createView()}
                                    select={view}
                                    click={value => {
                                        const index = view.indexOf(value)
                                        // const tmp = [...view]
                                        const tmp = []
                                        if(index === -1){
                                            tmp.push(value)
                                            setView(tmp)
                                        }
                                        else{
                                            // tmp.splice(index, 1)
                                            setView(tmp)
                                        }
                                    }}
                                />

                                <Select
                                    label="количество спален"
                                    data={createtip()}
                                    select={tip}
                                    type={'tip'}
                                    click={value => {
                                        const index = tip.indexOf(value)
                                        const tmp = [...tip]
                                        if(index === -1){
                                            tmp.push(value)
                                            settip(tmp)
                                        }
                                        else{
                                            tmp.splice(index, 1)
                                            settip(tmp)
                                        }
                                    }}
                                />

                                <Select
                                    label="особенности"
                                    data={createFeature()}
                                    select={features}
                                    click={value => {
                                        const index = features.indexOf(value)
                                        const tmp = [...features]
                                        if(index === -1){
                                            tmp.push(value)
                                            setFeatures(tmp)
                                        }
                                        else{
                                            tmp.splice(index, 1)
                                            setFeatures(tmp)
                                        }

                                    }}
                                />
                            </div>
                        </div>
                    </div> }
                </div>

                <div className="render">
                    <div className={(coef * 1080 < window.innerHeight ? 'render-plan render-plan-h' : 'render-plan') + (!isBack ? ' active' : '')}>
                        <svg width="1900" height="900" viewBox="0 0 1900 900">
                            {filteredFront.map((item, index) => (
                                <Fragment key={`item-${index}-front`}>
                                    <path
                                        data-floor={item.floor}
                                        // key={`item-front${index}`}
                                        fill="#fff"
                                        d={item.path}
                                        onClick={() => showPopupFloor(item.floor)}
                                        onMouseEnter={() => hoverFloor(item.floor, true)}
                                        onMouseLeave={() => hoverFloor(item.floor, false)}
                                        // onMouseEnter={e => showLabel(e, item)}
                                        // onMouseLeave={() => {
                                        // 		setLabel(null)
                                        // 		setLabelPos(null)
                                        // }}
                                    />
                                    <path
                                        data-floor-label={item.floor}
                                        // key={`item-front${index}-2`}
                                        fill="#1A1A1A"
                                        d={item.path_label}
                                        onClick={() => showPopupFloor(item.floor)}
                                        onMouseEnter={() => hoverFloor(item.floor, true)}
                                        onMouseLeave={() => hoverFloor(item.floor, false)}
                                    />
                                    <path
                                        data-floor-number={item.floor}
                                        // key={`item-front${index}-3`}
                                        fill="#fff"
                                        d={item.path_number}
                                        onClick={() => showPopupFloor(item.floor)}
                                        onMouseEnter={() => hoverFloor(item.floor, true)}
                                        onMouseLeave={() => hoverFloor(item.floor, false)}
                                    />
                                </Fragment>
                            ))}
                        </svg>
                        <img src="/img/build_front_wide.webp" alt="" />
                    </div>

                    <div className={(coef * 1080 < window.innerHeight ? 'render-plan render-plan-h' : 'render-plan') + (isBack ? ' active' : '')}>
                        <svg width="1900" height="900" viewBox="0 0 1900 900">
                            {filteredBack.map((item, index) => (
                                <Fragment key={`item-${index}-back`}>
                                    <path
                                        data-floor={item.floor}
                                        fill="#fff"
                                        d={item.path}
                                        // key={`item-back${index}`}
                                        onClick={() => showPopupFloor(item.floor)}
                                        onMouseEnter={() => hoverFloor(item.floor, true)}
                                        onMouseLeave={() => hoverFloor(item.floor, false)}
                                    />
                                    <path
                                        data-floor-label={item.floor}
                                        // key={`item-back${index}-2`}
                                        fill="#1A1A1A"
                                        d={item.path_label}
                                        onClick={() => showPopupFloor(item.floor)}
                                        onMouseEnter={() => hoverFloor(item.floor, true)}
                                        onMouseLeave={() => hoverFloor(item.floor, false)}
                                    />
                                    <path
                                        data-floor-number={item.floor}
                                        // key={`item-back${index}-3`}
                                        fill="#fff"
                                        d={item.path_number}
                                        onClick={() => showPopupFloor(item.floor)}
                                        onMouseEnter={() => hoverFloor(item.floor, true)}
                                        onMouseLeave={() => hoverFloor(item.floor, false)}
                                    />
                                </Fragment>
                            ))}
                        </svg>
                        {/* {filteredBack.map((item, index) => {
                            <p key={`item-${index}-back`}>
                                {item.floor}
                            </p>
                        })} */}
                        <img src="/img/build_back_wide.webp" alt="" />
                    </div>

                    {label && labelPos && (
                        <>
                            <div className="render-label" style={{left: `${labelPos.x}px`, top: `${labelPos.y}px`}}>
                                <div className={'rl__in'}>
                                    <div className={'rl__num'}>
                                        {label?.number}
                                    </div>
                                    <div>
                                        <div className={'rl_param'}>
                                            <div>
                                                <div className={'rl_t'}>Этаж</div>
                                                <div className={'rl_v'}>{label?.floor}/8</div>
                                            </div>
                                            <div>
                                                <div className={'rl_t'}>Вид</div>
                                                {/*
                                                <div className={'rl_v'} dangerouslySetInnerHTML={{ __html: label?.windowView?.map(item => item.title).join(', ') }} />
                                                */}
                                                <div className={'rl_v'}>{label?.view ? label?.view : label?.window ? label?.window : ''}</div>

                                            </div>
                                        </div>
                                        <div className={'rl_param'}>
                                            <div>
                                                <div className={'rl_t'}>Площадь</div>
                                                <div className={'rl_v'}>{label?.square} м<sup><small>2</small></sup></div>
                                            </div>
                                            <div>
                                                <div className={'rl_t'}>Спален</div>
                                                <div className={'rl_v'}>{label?.tip} {label?.tip=='1' ? `спальня`: label?.tip=='5' ? `спален`: label?.tip=='Пентхаус' ? ``: `спальни`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*{label?.windowView[0]?.img && (
                            <div className="render-view" style={{ backgroundImage: `url(${label?.windowView[0]?.img.split('|')[0]})` }}></div>
                            )}*/}
                        </>
                    )}

                </div>

            </div>
        </>
    )
}

export default Layout;
