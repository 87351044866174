//import Footer from './Footer'
import floors from "../data/floors";
import { useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import domain from "./domain";
import Slider from "react-slick";

const Popup = ({data, close, setPopupFlat, showPopup, setPopupImage}) => {
    const imgParams = '?v=2'

    const frmt = new Intl.NumberFormat()

    const [showPrice, setShowPrice] = useState(false)

    useEffect(() => {
        const popup = document.querySelector('.popup')
        popup.scrollTop = 0
    }, [data]);

    const toPlan = () => {
        const popup = document.querySelector('.popup')
        const element = document.querySelector('.popup__plan-title')
        popup.scrollTo({top: element.offsetTop, behavior: 'smooth'})
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const createWindowView = () => {
        if(Array.isArray(data?.windowView) && data?.windowView?.length){
            const tmp = []

            data?.windowView.forEach((item, index) => {

                const imgs = item?.img?.split('|').filter(el => !!el)

                tmp.push(
                    <div key={`item${index}`}>

                        <Slider {...settings} className="window-slick">
                            {imgs.map((el, indexEl) => (
                                <div key={`item${index}${indexEl}`}>
                                    <div className="popup__label-view-img" onClick={() => setPopupImage(el)} style={{ backgroundImage: `url(${el})` }} />
                                </div>
                            ))}
                        </Slider>


                        <div className="popup__label-view-dsc" dangerouslySetInnerHTML={{ __html: item?.title }} />
                    </div>
                )
            });

            return tmp
        }

        return null
    }

    return(
        <div className="popup">
            <div className="popup__head">
                <div className="popup__back" onClick={() => close(data?.floor)}>
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.469669 5.96967C0.176776 6.26256 0.176776 6.73744 0.469669 7.03033L5.24264 11.8033C5.53553 12.0962 6.01041 12.0962 6.3033 11.8033C6.59619 11.5104 6.59619 11.0355 6.3033 10.7426L2.06066 6.5L6.3033 2.25736C6.59619 1.96447 6.59619 1.48959 6.3033 1.1967C6.01041 0.903805 5.53553 0.903805 5.24264 1.1967L0.469669 5.96967ZM16 5.75L0.999999 5.75L0.999999 7.25L16 7.25L16 5.75Z" fill="#44453F"/>
                    </svg>
                    <span>Назад к выбору</span>
                </div>
                <div>
                    <img src="/img/logo_b.svg" alt="" />
                </div>
                <div/>
                {/* <div onClick={close}>
                    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.06452" y1="-1.06452" x2="42.5806" y2="-1.06452" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 31.9355 34.0644)" stroke="#B5B5B1" strokeWidth="2.12903" strokeLinecap="square"/>
                        <line x1="1.06452" y1="-1.06452" x2="42.5806" y2="-1.06452" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0 31.9356)" stroke="#B5B5B1" strokeWidth="2.12903" strokeLinecap="square"/>
                    </svg>
                </div> */}
            </div>

            <div className="popup__in">
                <div className="popup__in-top">

                    <div className={'p_l'}>

                        {/* <div className={'p_l__block'}>
                            <div className={'p_l__block__num'}>{data?.number}</div>
                            <div className={'p_l__block__floor'}>этаж {data?.floor}/8</div>
                        </div> */}

                        <div className={'p_block'}>
                            <div className={'p_param'}>
                                <div className={'p_param__label'}>Площадь</div>
                                <div className={'p_param__value'}>
                                    <span className={'p_param__num'}>{data?.square}</span> <span className={'area'}>м<sup><small>2</small></sup></span>
                                </div>
                            </div>
                        </div>

                        <div className={'p_params'}>

                            <div className={'p_param'}>
                                <div className={'p_param__label'}>Cпальни</div>
                                <div className={'p_param__value'}>
                                <span className={'p_param__num'}>{data?.tip}</span>
                                </div>
                            </div>
                        </div>

                        <div className={'p_params'}>

                            {(data?.view || data?.window) &&
                                <div className={'p_param'}>
                                    <div className={'p_param__label'}>Вид из окна</div>
                                    <div className={'p_param__value'}>
                                        {/* {data?.windowView?.map((item, index) => (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: item.title }} />
                                        ))} */}
                                        {data?.view ? data?.view : data?.window ? data?.window : ''}
                                    </div>
                                </div>
                            }

                            {data?.features?.length>0 &&
                                <div className={'p_param'}>
                                    <div className={'p_param__label'}>Особенности:</div>
                                    <div className={'p_param__value'}>
                                        {data?.features?.map((item, index) => (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: item.name }} />
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={'p_params'}>
                            <div className={'p_params__price'}>
                                {showPrice && (
                                    <div className={'p_param -price'} onClick={() => setShowPrice(false)}>
                                        <div className={'p_param__label'}>Стоимость:</div>
                                        <div className={'p_param__value'}>
                                            {frmt.format(data?.price)}&nbsp;₽
                                        </div>
                                    </div>
                                )}
                                {!showPrice && (
                                    <div className="popup__price-icon" onClick={() => setShowPrice(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 13" fill="none">
                                            <path d="M0 0.272461C0 0.272461 6.62646 4.27246 17.438 4.27246C28.2496 4.27246 34.8761 0.272461 34.8761 0.272461C34.8761 0.272461 41.5852 4.27246 52.4151 4.27246C63.2451 4.27246 70 0.272461 70 0.272461V12.9997H0V0.272461Z" fill="#898A8D"/>
                                        </svg>
                                    </div>
                                )}

                            </div>
                        </div>


                        {/* <div className="popup__label">
                            <div className="popup__label-title">
                                Апартамент {data?.number}
                            </div>

                            <div className="popup__label-dsc">{data?.dsc}</div>

                            <div className="popup__label-row">
                                <div>Количество спален</div>
                                <div className="space" />
                                <div>{data?.tip}</div>
                            </div>

                            <div className="popup__label-row">
                                <div>Площадь</div>
                                <div className="space" />
                                <div>{data?.square} м²</div>
                            </div>

                            <div className="popup__label-row">
                                <div>Этаж</div>
                                <div className="space" />
                                <div>{data?.floor} из 8</div>
                            </div>

                            <div className="popup__label-row">
                                <div>Нижний уровень</div>
                                <div className="space" />
                                <div>{data?.squareLow} м²</div>
                            </div>

                            <div className="popup__label-row">
                                <div>Верхний уровень</div>
                                <div className="space" />
                                <div>{data?.squareHigh} м²</div>
                            </div>

                            <div className="popup__label-row popup__label-row-view">
                                <div>Вид из окна</div>
                                <div className="space" />
                                <div>
                                    {data?.windowView?.map((item, index) => (
                                        <div key={index} dangerouslySetInnerHTML={{ __html: item.title }} />
                                    ))}
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className={'p_c'}>
                        <div className="p_c-text">Брюсов переулок</div>

                        <img src={data?.plan + imgParams} alt="" onClick={setPopupFlat} />

                        <div className="p_c-text">Двор</div>
                    </div>

                    <div className={'p_r'}>
                        <div className={'p_block'}>
                            <div className={'p_param'}>
                                <div className={'p_param__label'}>этаж</div>
                                <div className={'p_param__value'}>
                                    <span className={'p_param__num'}>{data?.floor}</span> <span className={'floor'}>/8</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* <div className="popup__label-view-title">Вид из окон</div>
                            {createWindowView()}*/}
                        </div>
                        <div>
                            <div>
                                <img onClick={toPlan} src={data?.genplan + imgParams} alt="" className="popup__mini-genplan" />
                                <div>
                                    <img className={'view_rose'} src={'/img/view_rose.svg'} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {data?.pano?.length>0 &&
                    data.pano.map((pano, index) => (
                        <iframe 
                            className="popup__pano" 
                            src={pano} 
                            title={"pano"+index} 
                            key={"pano"+index} 
                        />
                    ))
                }

                <div className="popup__plan-title">План этажа</div>
                <div className="popup__plan">
                    <div>
                        <div className="popup__plan-floor">
                            <svg width={floors[data?.floor]?.size[0]} height={floors[data?.floor]?.size[1]} viewBox={`0 0 ${floors[data?.floor]?.size[0]} ${floors[data?.floor]?.size[1]}`}>
                                {floors[data?.floor]?.flats?.map((item, index) => (data && 
                                    <path 
                                        onClick={() => showPopup({floor: data?.floor, number: item?.number})} 
                                        d={item.path} 
                                        fill={data.number === item.number ? '#fff' : '#2C2D32'} 
                                        key={`item${index}`} 
                                    />
                                ))}
                            </svg>
                            <img src={data['floor-plan'] + imgParams} alt="" />
                            {/* <img src={floors[data?.floor]?.img} alt="" /> */}
                        </div>
                        <img className={'view_rose'} src={data['roza']} alt="" />
                    </div>
                    {/* <div>
                        <img className={'view_rose'} src={'/img/view_rose.svg'} alt="" />
                    </div> */}
                </div>
            </div>

            {/*<Footer />*/}
        </div>
    )
}

export default Popup
